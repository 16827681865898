.sticky-navbar-fix{
    padding-top: 100px;
}

.masthead{
    min-height: 500px;
}

// #client-admin-masthead{
//     background-color: #0c304f;
//     background-image: url("../assets/img/masthead-1800x600.png");
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: auto;   
      
// }