// GP-Fearless 4.6.0


// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap" !default;
@if $web-font-path {
  @import url($web-font-path);
}



// Navbar ======================================================================

.navbar {
  font-size: $font-size-sm;
  font-weight: $navbar-font-weight;

}

.navbar-dark{
  input{
    border: none;
  }
}

.bg-primary {
  
  .dropdown-menu {
    background-color: $primary;

    .dropdown-item,
    .dropdown-item:focus {
      color: $navbar-dark-color;
    }

    .dropdown-item.active,
    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: darken($primary, 3%);
      color: $white;
    }
  }
}

.bg-dark {
  background-color: $gray-800 !important;

  .dropdown-menu {
    background-color: $gray-800;

    .dropdown-item,
    .dropdown-item:focus {
      color: $navbar-dark-color;
    }

    .dropdown-item.active,
    .dropdown-item:hover,
    .dropdown-item:focus  {
      background-color: darken($gray-800, 5%);
      color: $white;
    }
  }
}

.bg-brand {
  background-color: $brand-blue !important;

  .dropdown-menu {
    background-color: $brand-blue ;

    .dropdown-item,
    .dropdown-item:focus {
      color: $navbar-dark-color;
    }

    .dropdown-item.active,
    .dropdown-item:hover,
    .dropdown-item:focus  {
      background-color: darken($brand-blue, 5%);
      color: $white;
    }
  }
}

.bg-light {
  .dropdown-menu {
    background-color: $gray-200;

    .dropdown-item,
    .dropdown-item:focus {
      color: $navbar-light-color;
    }

    .dropdown-item.active,
    .dropdown-item:hover,
    .dropdown-item:focus  {
      background-color: lighten($gray-200, 5%);
      color: $navbar-light-hover-color;
    }
  }
}

// Navbar inline forms/search custom============================================
.navbar .nav-search {
  .btn,
  input{
    font-size: 0.9rem;
  }

  input{
    background-color: rgba( $white,0.8 );
    border-color: white !important;
    padding: 0 8px 0 8px;
  }
  input:focus{
    background-color: rgba( $white,1 );
  }

  .btn{
    background-color: rgba( $white,0.95 );
  }
  
}

// Buttons =====================================================================

.btn {

  &-primary {
    border-color: darken($primary, 5%);
  }

  &-secondary {
    border-color: darken($secondary, 5%);
  }

  &-success {
    border-color: darken($success, 5%);
  }

  &-info {
    border-color: darken($info, 5%);
    color: $white;
  }

  &-danger {
    border-color: darken($danger, 5%);
  }

  &-warning {
    border-color: darken($warning, 5%);
    color: $white;
  }

  &-light {
    border-color: darken($light, 5%);
  }

  &-dark {
    border-color: darken($dark, 5%);
  }
}

$disabled-fade: 0.8;
.btn {
  // color: darken( $white , 20%);
  &-primary.disabled,
  &-success.disabled,
  &-info.disabled,
  &-warning.disabled,
  &-danger.disabled
  {
    color: rgba( $white, $disabled-fade )
  }
  &-secondary.disabled
  {
    color: rgba( $dark, $disabled-fade )
  }
}


$btn-ol-width: 1px;


.btn-outline {
  &-primary {
    border-width: $btn-ol-width;
  }

  &-secondary {
    border-width: $btn-ol-width;
    border-color: darken($secondary, 20%);
    color: darken($secondary, 40%);
  }


  &-success {
    border-width: $btn-ol-width;
  }

  &-info {
    border-width: $btn-ol-width;
  }

  &-danger {
    border-width: $btn-ol-width;
  }

  &-warning {
    border-width: $btn-ol-width;
  }

  &-light {
    border-width: $btn-ol-width;
  }

  &-dark {
    border-width: $btn-ol-width;
  }
}

.btn-group {
  .dropdown-menu {
    border-top-width: 0;
  }

  .dropdown-toggle {
    &.btn-primary ~ .dropdown-menu {
      background-color: $primary;
      border-color: darken($primary, 5%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: darken($primary, 8%);
      }
    }

    &.btn-secondary ~ .dropdown-menu {
      background-color: $secondary;
      border-color: darken($secondary, 5%);

      .dropdown-item {
        color: $body-color;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: darken($secondary, 8%);
      }
    }

    &.btn-success ~ .dropdown-menu {
      background-color: $success;
      border-color: darken($success, 5%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: darken($success, 8%);
      }
    }

    &.btn-info ~ .dropdown-menu {
      background-color: $info;
      border-color: darken($info, 5%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: darken($info, 8%);
      }
    }

    &.btn-warning ~ .dropdown-menu {
      background-color: $warning;
      border-color: darken($warning, 5%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: darken($warning, 8%);
      }
    }

    &.btn-danger ~ .dropdown-menu {
      background-color: $danger;
      border-color: darken($danger, 5%);

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: darken($danger, 8%);
      }
    }
  }
}

// Typography ==================================================================

.text-secondary {
  color: $gray-700 !important;
}

.blockquote{
  p{
    margin-bottom: 2px;
  }
}

.blockquote-footer {
  color: $gray-600;
}

// Tables ======================================================================

table {
  font-size: $font-size-sm;
}

.table {
  &-primary,
  &-success,
  &-info,
  &-warning,
  &-danger {
    color: $white;
  }

  &-primary {
    &,
    > th,
    > td {
      background-color: $primary;
    }
  }

  &-secondary {
    &,
    > th,
    > td {
      background-color: $secondary;
    }
  }

  &-light {
    &,
    > th,
    > td {
      background-color: $light;
    }
  }

  &-dark {
    &,
    > th,
    > td {
      background-color: $dark;
    }
  }

  &-success {
    &,
    > th,
    > td {
      background-color: $success;
    }
  }

  &-info {
    &,
    > th,
    > td {
      background-color: $info;
    }
  }

  &-danger {
    &,
    > th,
    > td {
      background-color: $danger;
    }
  }

  &-warning {
    &,
    > th,
    > td {
      background-color: $warning;
    }
  }

  &-active {
    &,
    > th,
    > td {
      background-color: $table-active-bg;
    }
  }

  &-hover {
    .table-primary:hover {
      &,
      > th,
      > td {
        background-color: darken($primary, 5%);
      }
    }

    .table-secondary:hover {
      &,
      > th,
      > td {
        background-color: darken($secondary, 5%);
      }
    }

    .table-light:hover {
      &,
      > th,
      > td {
        background-color: darken($light, 5%);
      }
    }

    .table-dark:hover {
      &,
      > th,
      > td {
        background-color: darken($dark, 5%);
        color: darken($white, 10%);
      }
    }

    .table-success:hover {
      &,
      > th,
      > td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {
      &,
      > th,
      > td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {
      &,
      > th,
      > td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {
      &,
      > th,
      > td {
        background-color: darken($warning, 5%);
      }
    }

    .table-active:hover {
      &,
      > th,
      > td {
        background-color: $table-active-bg;
      }
    }

  }
}

// Forms =======================================================================

label,
.control-label,
.help-block,
.checkbox,
.radio,
.form-control-feedback {
  font-size: $font-size-sm;
}

.form-control{

}


// Forms

$label-margin-bottom:                   .5rem !default;

// $input-padding-y:                       .8rem !default;
// $input-padding-x:                       1rem !default;
// $input-btn-padding-y:         .5rem !default;
// $input-btn-padding-x:         .75rem !default;


// $input-padding-y:                       $input-btn-padding-y  !default;
// $input-padding-x:                       $input-btn-padding-x !default;
// $input-font-family:                     $input-btn-font-family !default;
// $input-font-size:                       $input-btn-font-size !default;
// $input-font-weight:                     $font-weight-base !default;
// $input-line-height:                     $input-btn-line-height !default;

// $input-padding-y-sm:                    $input-btn-padding-y-sm !default;
// $input-padding-x-sm:                    $input-btn-padding-x-sm !default;
// $input-font-size-sm:                    $input-btn-font-size-sm !default;
// $input-line-height-sm:                  $input-btn-line-height-sm !default;

// $input-padding-y-lg:                    $input-btn-padding-y-lg !default;
// $input-padding-x-lg:                    $input-btn-padding-x-lg !default;
// $input-font-size-lg:                    $input-btn-font-size-lg !default;
// $input-line-height-lg:                  $input-btn-line-height-lg !default;



// $input-focus-bg:                        $input-bg !default;
// $input-focus-border-color:              lighten($component-active-bg, 25%) !default;
// $input-focus-color:                     red !important
// $input-focus-width:                     $input-btn-focus-width !default;
// $input-focus-box-shadow:                $input-btn-focus-box-shadow !default;
// $custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow !default;
// $custom-control-indicator-focus-border-color:   $input-focus-border-color !default;





// Navs ========================================================================

.dropdown-item {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: $font-size-sm;
  font-weight: 300;
}

.tab-content{
  margin-top: 10px;
}

.nav-tabs {
  .nav-link,
  .nav-link.disabled,
  .nav-link.disabled:hover,
  .nav-link.disabled:focus {
    // border-color: $gray-300;// $nav-tabs-border-color;
    // background-color: $gray-200;
   border-radius: 5px 5px 0 0;
    border-bottom: transparent;
  }

  .nav-link:hover,
  .nav-link:focus {
    // border: 1px solid transparent;
    // text-decoration: underline;
  }
}

.nav-pills {
  .active {
    // border: 1px solid darken($primary, 5%);
  }
}


// .nav-tabs .nav-link, .nav-tabs .nav-link.disabled, .nav-tabs
// .nav-link.disabled:hover, .nav-tabs .nav-link.disabled:focus {

.breadcrumb {
  // border: 1px solid $dropdown-border-color;
  // border-radius: 3px;
  // border-bottom: 1px solid $dropdown-border-color;
  font-size: $font-size-sm;
  font-weight: 400;
  // text-transform: uppercase;
  background: none;
  a{
    text-decoration: underline;
  }
}



.pagination {
  font-weight: 400;
  // font-size: $font-size-sm;

  .page-link {
    border-color: $gray-200;
    // border-radius: 3px;
    // margin-left: .1em;
    // margin-right: .1em;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .page-item.disabled {
    .page-link {
      // border-color: transparent;
      border-color: $gray-200;
    }
  }
}

.pagination,
.pagination-lg,
.pagination-sm {
  .page-item:first-child,
  .page-item:last-child {
    .page-link {
      // border-radius: 3px;
    }
  }
}

// List groups

.list-group {
  font-size: $font-size-sm;
  font-weight: 300;
}

// Indicators ==================================================================

.close {
  opacity: .6;
  text-shadow: none;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.alert {
  font-size: $font-size-sm;
  font-weight: 300;
  color: $white;

  &-primary {
    &,
    > th,
    > td {
      background-color: $primary;
    }
  }

  &-secondary {
    &,
    > th,
    > td {
      background-color: $secondary;
    }
  }

  &-success {
    &,
    > th,
    > td {
      background-color: $success;
    }
  }

  &-info {
    &,
    > th,
    > td {
      background-color: $info;
    }
  }

  &-danger {
    &,
    > th,
    > td {
      background-color: $danger;
    }
  }

  &-warning {
    &,
    > th,
    > td {
      background-color: $warning;
    }
  }

  &-dark {
    &,
    > th,
    > td {
      background-color: $dark;
    }
  }

  &-light {
    &,
    > th,
    > td {
      background-color: $light;
    }
  }

  .alert-link {
    font-weight: 400;
    color: $white;
    text-decoration: underline;
  }

  &-secondary,
  &-light {
    &,
    a:not(.btn),
    .alert-link {
      color: $body-color;
    }
  }
}

.badge {
  &-warning,
  &-info {
    color: $white;
  }
}

// Progress bars ===============================================================
.progress{
  margin-bottom: 10px;
}

.progress[value] {
  height: 22px;
  padding: 2px;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  @include box-shadow(none);
}


// Popovers      ===============================================================

.popover-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
