section {
  // padding-top: 30px;
  // padding-bottom: 80px;
}

section:not(:first-child) {
  // border-bottom: 1px solid $secondary;
}

.page-header {
  margin-bottom: 20px;
}

.navbar-minimal {
  border-bottom: 1px solid $gray-200;
  background: white;
}

.short-hr {
  border: none;
  border-top: 3px solid $gray-400;
  width: 100px;
  margin-left: 0;
  margin-bottom: 50px;
}

.color-swatch {
  height: 30px;
  width: 30px;
  border-radius: 10px;
  margin: 5px;
  margin-right: 15px;
}

.list-group {
  margin-bottom: 15px;
}

html,
body {
  overflow-x: hidden;
}

.kristen-text {
  top: 100px;
  left: 300px;
  height: 300px;
  width: 300px;
  background-color: red;
  text-align: center;
}


.intro {
  h1 {
  }
  ul {
  }
}


$c-wid: 480px;
$l-color: $primary;

.section-title {
  padding-bottom: 8px;
  // color: $primary;
  // border-bottom: 2px solid #ccc;
  font-size: 1.7rem;
  // display: inline-block;
  max-width: $c-wid;
}

.socials {
  

  i {
    font-size: 2rem;
    margin-right: 10px;
    text-decoration: none !important;
  }

  .nav-link {
    color: $l-color;
    padding: 10px 10px;
    span {
      text-decoration: underline;
    }
    
  }
  
   .nav{
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
  }

  .nav-item {

    :hover {
      color: lighten($l-color, 5%);
      i{
        margin-top: -1px;
      }
      
    }
  }
  .nav-item:not(:first-child) {
    // margin-left: 20px;
  }

 
  @include media-breakpoint-down(sm) {
    .nav{
      display: flex;
      flex-direction: column;
       padding: 5px 30px;
    }
    .nav-item {
      margin-left: 0 !important;
    }
  }


}

.nav-brand-text {
  font-size: 2rem;
  font-weight: 300;
  color: $white;
  background-color: $black;
}


.nav-brand-sub {
  // font-size: 2rem;
  // margin-top: -10px;
  font-weight: 300;
  color: #999;
}
.contact-card{
  // margin-top: 10px;
  display: flex;
  max-width: $c-wid;
  justify-content: center;
  img{
    width: 150px;
    height: 150px;
  }
}
.services{
  font-size: 1.2rem;
  max-width: $c-wid;
  margin-top: 0 !important;
}
footer {
  padding-top: 100px;
  font-size: 0.9rem;
}
